<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="title">
              AWS Partner Services
            </h1>
            <h4>
              As an AWS Partner, Firecrest Systems Ltd specialises in delivering cutting-edge cloud solutions to organisations across New Zealand. We leverage our expertise in AWS services to solve complex problems and drive digital transformation for our clients.
            </h4>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-about-description pb-0">
        <div class="container">
          <div class="md-layout">
            <div
              class="md-layout-item"
            >
              <h2 class="title text-center">
                Our AWS Strengths
              </h2>
              <h5 class="description">
                <ol>
                  <li><b>Compute and Storage Solutions:</b> We excel in utilising Amazon EC2 for scalable computing and Amazon S3 for secure, durable storage, enabling organisations to optimise their infrastructure.</li>
                  <li><b>Database Management:</b> Our team is proficient in Amazon RDS, providing managed database solutions that enhance performance and reduce administrative overhead.</li>
                  <li><b>Serverless Architecture:</b> We design and implement serverless applications using AWS Lambda and API Gateway, allowing for cost-effective and highly scalable solutions.</li>
                  <li><b>Contact Center Solutions:</b> With AWS Connect, we help organisations create efficient, cloud-based contact centers that improve customer experiences.</li>
                  <li><b>AI and Machine Learning:</b> Leveraging Amazon Bedrock, we integrate advanced AI capabilities into applications, driving innovation and automation.</li>
                  <li><b>Streaming Data Solutions:</b> We implement robust streaming architectures using Amazon Kinesis services:
                  <ol type="a">
                    <li>Amazon Kinesis Data Streams for scalable real-time data ingestion</li>
                    <li>Amazon Kinesis Data Firehose for easy loading of streaming data into AWS</li>
                  <li>Amazon Managed Service for advanced stream processing</li></ol></li>
                  <li><b>Content Delivery Network:</b> We leverage Amazon CloudFront to accelerate content delivery, enhance security, and improve user experience:
                  <ol type="a">
                    <li>Global edge locations and regional edge caches for low-latency content delivery</li>
                    <li>Integration with AWS Shield and AWS WAF for robust security</li>
                    <li>Support for both static and dynamic content delivery</li>
                  </ol></li>
                </ol>
              </h5>
            </div>
          </div>
          <div class="md-layout">
            <div
              class="md-layout-item"
            >
              <h2 class="title text-center">
                Why Choose Firecrest Systems as Your AWS Partner?
              </h2>
              <h5 class="description">
                <ul>
                  <li><b>Proven Expertise:</b> Our team has demonstrated the highest level of specialisation and deep AWS technical expertise across a wide range of services.</li>
                  <li><b>Custom Cloud Solutions:</b> We develop bespoke cloud-based software tailored to your specific organisational needs, incorporating the latest AWS technologies.</li>
                  <li><b>Long-term Support:</b> We offer ongoing support to ensure your AWS solutions continue to meet your evolving organisational requirements.</li>
                  <li><b>Local Knowledge:</b> As a New Zealand-based company, we understand the unique challenges of the local market.</li>
                  <li><b>Innovation-Driven:</b> Like our namesake, the Firecrest bird, we are constantly busy, innovative, and adaptable to new technologies.</li>
                  <li><b>Security-First Approach:</b> We implement best practices for securing your data and applications across all AWS services we utilise.</li>
                  <li><b>Performance Optimisation:</b> Our solutions are designed to maximize performance, leveraging AWS's global infrastructure and advanced features like CloudFront's continuous deployment.</li>
                </ul>
                By partnering with Firecrest Systems, you gain access to a team of AWS specialists who are committed to driving your organisation's forward through cloud technology. Whether you're looking to migrate to the cloud, optimise your existing infrastructure, implement real-time data analytics, or develop new cloud-native applications, we have the skills and experience to help you succeed.
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-about-office">
        <div class="container">
          <div class="md-layout text-center">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
              <h3>
                Contact us today to discover how we can transform your organisation with AWS-powered solutions and join the ranks of our satisfied clients across various industries.
              </h3>
            </div>
            <div class="md-layout-item md-size-100 mx-auto text-center">
          <lets-talk />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/material-kit-mixins";
import LetsTalk from "./components/LetsTalk";

export default {
  components: {
    LetsTalk,
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "aws-partner",
  data() {
    return {
      image: require("@/assets/img/AWS_463548093.jpg"),
    };
  }
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 1.5rem;
}
</style>
