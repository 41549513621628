<template>
  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="md-fixed mainmenu"
    :class="{
      [`md-${type}`]: !navbarTransparent,
      [extraNavClasses]: navbarTransparent,
      'md-transparent': navbarTransparent
    } "
    :color-on-scroll="colorOnScroll">
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start">
        <h3 class="md-title"><a href="#/"><img :src="'/images/FC-colourWeb.png'"/></a>
        </h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: toggledClass }"
          @click="toggleNavbarMobile()">
          <span class="icon-bar" />
          <span class="icon-bar" />
          <span class="icon-bar" />
        </md-button>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section="false">
              <!-- Here you can add your items from the section-start of your toolbar -->
            </mobile-menu>
            <md-list>
              <md-list-item href="#/" @click="toggleNavbarMobile()">
                <i class="material-icons">home</i> <p>Home</p>
              </md-list-item>
              <md-list-item href="#/about-us" @click="toggleNavbarMobile()">
                <i class="material-icons">layers</i> <p>About</p>
              </md-list-item>

              <li class="md-list-item">
                <a href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown">
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown">
                        <i class="material-icons">view_day</i> <p>Services</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li v-for="li in linksSections" :key="li.name">
                          <a :href="'#/' + li.href" @click="toggleNavbarMobile()">
                            <i class="material-icons">{{ li.icon }}</i>
                            {{ li.name.charAt(0).toUpperCase() + li.name.slice(1)}}
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>
             <!-- <md-list-item href="#/blog-posts">
                <i class="material-icons">view_quilt</i> <p>News & Views</p>
              </md-list-item>-->
              <md-list-item href="#/contact-us" @click="toggleNavbarMobile()">
                <i class="material-icons">location_on</i> <p>Contact</p>
              </md-list-item>

              <li class="md-list-item">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean"
                >
                  <div class="md-list-item-content">
                    <md-button
                      class="md-success" href="https://portal.firecrestsystems.com/" target="_blank" @click="toggleNavbarMobile()"
                    ><md-icon>login</md-icon>Portal Login</md-button>
                  </div>
                </a>
              </li>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

import MobileMenu from "@/layout/MobileMenu";
export default {
  components: {
    MobileMenu
  },
  props: {
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info",
          "dark"
        ].includes(value);
      }
    },
    colorOnScroll: {
      type: Number,
      default: 0
    },
    navbarTransparent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      extraNavClasses: "",
      toggledClass: false,
      brand: "Firecrest Systems",
      linksSections: [
        { name: "Custom Software Development", href: "software", icon: "dns" },
        { name: "Content Driven Website", href: "websites", icon: "dashboard" },
        { name: "Customer Care", href: "customer-care", icon: "people" },
        { name: "Design and Marketing", href: "design", icon: "palette" },
        { name: "Managed Device Services", href: "mds", icon: "build" },
        { name: "Point of Care Health", href: "poch", icon: "health_and_safety" },
        { name: "AWS Partner Services", href: "aws-partner", icon: "handshake" }
      ]
    };
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
    handleScroll() {
      let scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop;
      let navbarColor = document.getElementById("toolbar");
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `md-${this.type}`;
        navbarColor.classList.remove("md-transparent");
          return (this.isDefaultImage = true);
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
          navbarColor.classList.add("md-transparent");
        return (this.isDefaultImage = false);
        }
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    }
  }
};
</script>