import Vue from "vue";
import Router from "vue-router";

//  Main Pages
import Index from "./views/Index.vue";
import AboutUs from "./views/AboutUs.vue";
import Services from "./views/Services.vue";
import ContactUs from "./views/ContactUs.vue";
import Stories from "./views/Stories.vue";
import Terms from "./views/Terms.vue";
import Privacy from "./views/Privacy.vue";
import Documents from "./views/Documents.vue";
import TradeTerms from "./views/Trade-Terms.vue";
import Error from "./views/Error.vue";

//  Main Services
import Software from "./views/services/Software.vue";
import MDS from "./views/services/MDS.vue";
import Websites from "./views/services/Websites.vue";
import CustomerCare from "./views/services/CustomerCare.vue";
import Design from "./views/services/Design.vue";
import POCH from "./views/services/POCH.vue";
import AWSPartner from "./views/AWSPartner.vue";

//  Main Layout
import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      alias: ["/index", "/home"],
      name: "index",
      components: { default: Index, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        footer: { type: "black", size: "big" }
      }
    },
    {
      path: "/services",
      name: "services",
      components: { default: Services, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: false },
        footer: { type: "black", size: "big" }
      }
    },
    {
      path: "/about-us",
      name: "about-us",
      components: { default: AboutUs, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        footer: { type: "black", size: "big" }
      }
    },
    {
      path: "/contact-us",
      name: "contact-us",
      components: { default: ContactUs, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        footer: { type: "black", size: "big" }
      }
    },
    {
      path: "/software",
      name: "software",
      components: { default: Software, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        footer: { type: "black", size: "big" }
      }
    },
    {
      path: "/mds",
      name: "mds",
      components: { default: MDS, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        footer: { type: "black", size: "big" }
      }
    },
    {
      path: "/websites",
      name: "websites",
      components: { default: Websites, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        footer: { type: "black", size: "big" }
      }
    },
    {
      path: "/customer-care",
      name: "customer-care",
      components: { default: CustomerCare, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        footer: { type: "black", size: "big" }
      }
    },
    {
      path: "/design",
      name: "design",
      components: { default: Design, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        footer: { type: "black", size: "big" }
      }
    },
    {
      path: "/poch",
      name: "poch",
      components: { default: POCH, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        footer: { type: "black", size: "big" }
      }
    },
    {
      path: "/aws-partner",
      name: "aws-partner",
      components: { default: AWSPartner, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        footer: { type: "black", size: "big" }
      }
    },
    {
      path: "/stories",
      name: "stories",
      components: { default: Stories, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        footer: { type: "black", size: "big" }
      }
    },
    {
      path: "/terms",
      name: "terms",
      components: { default: Terms, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 0, navbarTransparent: false },
        footer: { type: "black", size: "big" }
      }
    },
    {
      path: "/privacy",
      name: "privacy",
      components: { default: Privacy, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 0, navbarTransparent: false },
        footer: { type: "black", size: "big" }
      }
    },
    {
      path: "/documents",
      name: "documents",
      components: { default: Documents, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 0, navbarTransparent: false },
        footer: { type: "black", size: "big" }
      }
    },
    {
      path: "/trade-terms",
      name: "trade-terms",
      components: { default: TradeTerms, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 0, navbarTransparent: false },
        footer: { type: "black", size: "big" }
      }
    },
    {
      path: "/error-page",
      name: "error",
      components: { default: Error, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 50, navbarTransparent: true },
        footer: { type: "black", size: "big" }
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
